import * as Ports from '../core/network/Ports'

// Describes settings for how
interface Config {
  auth0Audience: string
  auth0ClientID: string
  auth0Domain: string

  // Also known as the callback URL in the auth0 application settings.
  auth0RedirectURI: string

  graphqlURL: string
}

// TODO: Move config to compile time.
function getConfig(): Config {
  const isDevelopment = window.location.hostname === 'localhost'
  return isDevelopment
    ? {
        auth0Audience: 'serverInternal',
        auth0ClientID: 'neDZWa5gIJmSmYBs0RozQvBP75S2sfvp',
        auth0Domain: 'dev-qohsthgg.us.auth0.com',
        auth0RedirectURI: `http://${window.location.host}`,
        graphqlURL: `http://${window.location.hostname}:${Ports.server}/graphql`,
      }
    : {
        auth0Audience: 'serverInternal',
        auth0ClientID: 'PmGAJXdJPUzZHwLLoT5ykHC9WRQVKfjD',
        auth0Domain: 'dev-qohsthgg.us.auth0.com',
        auth0RedirectURI: 'https://internal2.mistypath.com',
        graphqlURL: 'https://internalapi2.mistypath.com/graphql',
      }
}

const config = getConfig()
export default config
