import * as WebInternalPaths from '../WebInternalPaths'

import React from 'react'
import type {WebInternalApp} from './WebInternalAppTypes'
import WebInternalBinPacking from '../bin-packing/WebInternalBinPacking'
import WebInternalCalculator from '../calculator/WebInternalCalculator'
import WebInternalHome from '../home/WebInternalHome'
import WebInternalPlan from '../plan/WebInternalPlan'
import WebInternalReceiptCostco from '../receipt-costco/WebInternalReceiptCostco'

import invariant from 'invariant'
import {useLocation} from 'react-router-dom'

import {
  IconBox,
  IconCalculator,
  IconGlobe,
  IconHome,
  IconReceipt2,
} from '@tabler/icons'

const webInternalApps: WebInternalApp[] = [
  {
    element: <WebInternalHome />,
    icon: IconHome,
    name: 'Home',
    routePath: WebInternalPaths.home,
    themeColor: 'blue',
  },
  {
    element: <WebInternalBinPacking />,
    icon: IconBox,
    name: 'Bin Packing',
    routePath: WebInternalPaths.binPacking,
    themeColor: 'orange',
  },
  {
    element: <WebInternalCalculator />,
    icon: IconCalculator,
    name: 'Calculator',
    routePath: WebInternalPaths.calculator,
    themeColor: 'grape',
  },
  {
    element: <WebInternalReceiptCostco />,
    icon: IconReceipt2,
    name: 'Costco Receipt',
    routePath: WebInternalPaths.costcoReceipt,
    themeColor: 'red',
  },
  {
    element: <WebInternalPlan />,
    icon: IconGlobe,
    name: 'Planner',
    routePath: WebInternalPaths.planner,
    themeColor: 'lime',
  },
]

export function useWebInternalApp(): WebInternalApp {
  const location = useLocation()

  // Find the longest match.
  const matches = webInternalApps
    .filter(
      app =>
        location.pathname === app.routePath ||
        location.pathname.startsWith(app.routePath),
    )
    .sort((a, b) => b.routePath.length - a.routePath.length)

  invariant(matches.length > 0, 'Unknown app path: ' + location.pathname)
  return matches[0]
}

export {webInternalApps}
