import {IconAlertCircle} from '@tabler/icons'
import {Prism} from '@mantine/prism'
import type {ReactElement} from 'react'
import type {WebInternalAppDebugModalQuery} from './__generated__/WebInternalAppDebugModalQuery.graphql'
import WebInternalConfig from '../WebInternalConfig'

import {useAuth0} from '@auth0/auth0-react'

import {Alert, Modal, Text} from '@mantine/core'
import React, {useEffect, useState} from 'react'

import {graphql, useLazyLoadQuery} from 'react-relay'

interface Props {
  opened: boolean
  onClose(): void
}

export default function WebInternalAppDebugModal({
  onClose,
  opened,
}: Props): ReactElement | null {
  const data = useLazyLoadQuery<WebInternalAppDebugModalQuery>(
    graphql`
      query WebInternalAppDebugModalQuery {
        personSelf {
          id
        }
      }
    `,
    {},
  )

  const {getAccessTokenSilently, user} = useAuth0()
  const [accessToken, setAccessToken] = useState('')

  useEffect(() => {
    async function updateAccessToken(): Promise<void> {
      const newAccessToken = await getAccessTokenSilently({
        audience: WebInternalConfig.auth0Audience,
      })
      setAccessToken(newAccessToken)
    }
    if (opened) {
      void updateAccessToken()
    }
  }, [getAccessTokenSilently, opened])

  if (!opened) {
    return null
  }

  const graphiqlRequestHeaders = JSON.stringify(
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${accessToken}`,
    },
    null,
    2,
  )

  return (
    <Modal opened={opened} onClose={onClose} size='xl' title='Debug info'>
      <Text size='lg'>User info</Text>
      <ul>
        <li>Auth ID: {user?.sub}</li>
        <li>Email: {user?.email}</li>
        <li>EntPerson ID: {data.personSelf.id}</li>
      </ul>

      <Text mt='md' size='lg'>
        GraphiQL request headers
      </Text>
      <div>
        Paste this into GraphiQL&apos;s request headers to send authenticated
        requests.
      </div>
      <Alert color='orange' icon={<IconAlertCircle />} mt='md'>
        Handle this token carefully.
      </Alert>
      <Prism language='json' mt='md'>
        {graphiqlRequestHeaders}
      </Prism>
    </Modal>
  )
}
