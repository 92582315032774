import cleanStack from 'clean-stack'
import is from '@sindresorhus/is'

export interface ErrorSummaryOptions {
  noStack?: boolean
}

// Gets the summary of an error object.
export default function errorSummary(
  error: unknown,
  options?: ErrorSummaryOptions,
): string {
  if (is.error(error)) {
    if (error.stack != null && error.stack.length > 0 && !options?.noStack) {
      // This includes the error message and stack trace.
      return cleanStack(error.stack)
    } else if (error.message.length > 0) {
      return error.message
    } else {
      return JSON.stringify(error)
    }
  } else if (is.string(error)) {
    return error
  } else {
    return JSON.stringify(error)
  }
}
