import FullPageLoader from '../../web/components/FullPageLoader'
import type {ReactElement} from 'react'
import {RelayEnvironmentProvider} from 'react-relay'
import WebInternalAuthLoginPage from './WebInternalAuthLoginPage'
import WebInternalConfig from '../WebInternalConfig'

import useWebInternalAuthRelayEnvironment from './useWebInternalAuthRelayEnvironment'

import {Auth0Provider, useAuth0} from '@auth0/auth0-react'
import React, {Suspense} from 'react'

interface Options {
  children: ReactElement
}

// Provides authentication for the app.
export default function WebInternalAuthProvider({
  children,
}: Options): ReactElement {
  return (
    <Auth0Provider
      audience={WebInternalConfig.auth0Audience}
      cacheLocation='localstorage'
      domain={WebInternalConfig.auth0Domain}
      clientId={WebInternalConfig.auth0ClientID}
      redirectUri={WebInternalConfig.auth0RedirectURI}
      useRefreshTokens={true}
    >
      <AuthContainer>{children}</AuthContainer>
    </Auth0Provider>
  )
}

function AuthContainer({children}: Options): ReactElement {
  const {isAuthenticated, isLoading} = useAuth0()
  const environment = useWebInternalAuthRelayEnvironment()

  if (isLoading) {
    return <FullPageLoader description='Authenticating...' />
  } else if (!isAuthenticated) {
    return <WebInternalAuthLoginPage />
  } else if (environment == null) {
    return <FullPageLoader description='Setting up GraphQL...' />
  } else {
    return (
      <RelayEnvironmentProvider environment={environment}>
        <Suspense fallback={<FullPageLoader description='Loading app...' />}>
          {children}
        </Suspense>
      </RelayEnvironmentProvider>
    )
  }
}
