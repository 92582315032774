/**
 * @generated SignedSource<<00e140527936529e5dba17dee7362fce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WebInternalAppDebugModalQuery$variables = {};
export type WebInternalAppDebugModalQuery$data = {
  readonly personSelf: {
    readonly id: string;
  };
};
export type WebInternalAppDebugModalQuery = {
  variables: WebInternalAppDebugModalQuery$variables;
  response: WebInternalAppDebugModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Person",
    "kind": "LinkedField",
    "name": "personSelf",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WebInternalAppDebugModalQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WebInternalAppDebugModalQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8c6996b839b45bac5f2e5d9391b5ed28",
    "id": null,
    "metadata": {},
    "name": "WebInternalAppDebugModalQuery",
    "operationKind": "query",
    "text": "query WebInternalAppDebugModalQuery {\n  personSelf {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fafd65e91916713294451731dba873c0";

export default node;
