/**
 * @generated SignedSource<<2a33db99dacd871fbb23960fe332cfbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WebInternalHomeQuery$variables = {
  skip: number;
  take: number;
};
export type WebInternalHomeQuery$data = {
  readonly photoItems: ReadonlyArray<{
    readonly id: string;
    readonly model: string;
    readonly weight: number;
  }>;
  readonly testAllPets: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type WebInternalHomeQuery = {
  variables: WebInternalHomeQuery$variables;
  response: WebInternalHomeQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "take"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "brand",
        "value": "Canon"
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "asc:model"
          },
          {
            "kind": "Variable",
            "name": "skip",
            "variableName": "skip"
          },
          {
            "kind": "Variable",
            "name": "take",
            "variableName": "take"
          }
        ],
        "kind": "ObjectValue",
        "name": "pagination"
      },
      {
        "kind": "Literal",
        "name": "type",
        "value": "lens"
      }
    ],
    "concreteType": "PhotoItem",
    "kind": "LinkedField",
    "name": "photoItems",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "model",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weight",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TestPet",
    "kind": "LinkedField",
    "name": "testAllPets",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WebInternalHomeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WebInternalHomeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d464bdd012ce85e977d0b220e5534927",
    "id": null,
    "metadata": {},
    "name": "WebInternalHomeQuery",
    "operationKind": "query",
    "text": "query WebInternalHomeQuery(\n  $skip: Int!\n  $take: Int!\n) {\n  photoItems(brand: \"Canon\", type: \"lens\", pagination: {orderBy: \"asc:model\", skip: $skip, take: $take}) {\n    id\n    model\n    weight\n  }\n  testAllPets {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "bf9a62ee366b643454502b51cbae530a";

export default node;
