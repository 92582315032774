import FullPage from './FullPage'
import React from 'react'
import type {ReactElement} from 'react'

import {Loader, Stack, Text} from '@mantine/core'

interface Props {
  description?: string
}

export default function FullPageLoader({description}: Props): ReactElement {
  return (
    <FullPage>
      <Stack align='center'>
        <Loader />
        <Text color='dimmed'>{description}</Text>
      </Stack>
    </FullPage>
  )
}
