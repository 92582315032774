import WebInternalConfig from '../WebInternalConfig'

import {useAuth0} from '@auth0/auth0-react'

import {Environment, Network, RecordSource, Store} from 'relay-runtime'
import type {GraphQLResponse, RequestParameters, Variables} from 'relay-runtime'
import {useCallback, useEffect, useMemo, useState} from 'react'

// Gets an authenticated relay environment object.
//
// Returns null if not authenticated.
//
// Note that we tried making this return an unauthenticated environment,
// but this results in unauthorized server errors which trigger error boundaries.
// This hook never updates its access token as a result.
export default function useWebInternalAuthRelayEnvironment(): Environment | null {
  const {getAccessTokenSilently, isAuthenticated} = useAuth0()
  const [accessToken, setAccessToken] = useState<string | null>(null)

  useEffect(() => {
    async function updateAccessToken(): Promise<void> {
      try {
        const newAccessToken = await getAccessTokenSilently({
          audience: WebInternalConfig.auth0Audience,
        })
        setAccessToken(newAccessToken)
      } catch (error: unknown) {
        // TODO: What are you supposed to do here? Calling getAccessTokenWithPopup
        // fails on iOS since it'll block the popup.
        console.log(error)
      }
    }
    if (isAuthenticated) {
      void updateAccessToken()
    }
  }, [getAccessTokenSilently, isAuthenticated])

  const fetchQuery = useCallback(
    async (
      operation: RequestParameters,
      variables: Variables,
    ): Promise<GraphQLResponse> => {
      const response = await fetch(WebInternalConfig.graphqlURL, {
        body: JSON.stringify({
          query: operation.text,
          variables,
        }),
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: accessToken != null ? `Bearer ${accessToken}` : '',

          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      return (await response.json()) as GraphQLResponse
    },
    [accessToken],
  )

  const environment = useMemo(
    () =>
      accessToken != null
        ? new Environment({
            network: Network.create(fetchQuery),
            store: new Store(new RecordSource()),
          })
        : null,
    [accessToken, fetchQuery],
  )

  return environment
}
