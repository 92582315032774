import type {ReactElement} from 'react'
import type {WebInternalHomeQuery} from './__generated__/WebInternalHomeQuery.graphql'

import React, {useCallback, useState} from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'

export default function WebInternalHome(): ReactElement {
  const [page, setPage] = useState(0)

  const onPreviousPage = useCallback(
    () => setPage(page => Math.max(0, page - 1)),
    [],
  )
  const onNextPage = useCallback(() => setPage(page => page + 1), [])

  const data = useLazyLoadQuery<WebInternalHomeQuery>(
    graphql`
      query WebInternalHomeQuery($skip: Int!, $take: Int!) {
        photoItems(
          brand: "Canon"
          type: "lens"
          pagination: {orderBy: "asc:model", skip: $skip, take: $take}
        ) {
          id
          model
          weight
        }
        testAllPets {
          id
          name
        }
      }
    `,
    {
      skip: page * 10,
      take: 10,
    },
  )
  return (
    <div>
      <div>Lenses:</div>
      <ul>
        {data.photoItems.map(photoItem => (
          <li key={photoItem.id}>
            {photoItem.model}: {photoItem.weight} grams
          </li>
        ))}
      </ul>
      <button onClick={onPreviousPage}>&lt;</button>
      <button onClick={onNextPage}>&gt;</button>

      <div>All pets:</div>
      <ul>
        {data.testAllPets.map(pet => (
          <li key={pet.id}>
            {pet.id}: {pet.name}
          </li>
        ))}
      </ul>
    </div>
  )
}
