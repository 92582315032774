import React from 'react'
import type {ReactElement} from 'react'

import {ActionIcon, CopyButton, Tooltip} from '@mantine/core'
import {IconCheck, IconClipboardCopy} from '@tabler/icons'

interface Props {
  icon?: ReactElement
  value: string
}

export default function ClipboardButton({icon, value}: Props): ReactElement {
  return (
    <CopyButton value={value}>
      {({copied, copy}) => (
        <Tooltip
          label={copied ? 'Copied' : 'Copy ' + value}
          withArrow
          position='right'
        >
          <ActionIcon color='dark' onClick={copy}>
            {copied ? (
              <IconCheck size={16} />
            ) : (
              icon ?? <IconClipboardCopy size={16} />
            )}
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButton>
  )
}
