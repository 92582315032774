import {Navbar} from '@mantine/core'
import NavbarLink from '../../web/components/NavbarLink'
import React from 'react'
import type {ReactElement} from 'react'

import {useWebInternalApp, webInternalApps} from './webInternalApps'

export default function WebInternalAppNavbar(): ReactElement {
  const currentApp = useWebInternalApp()

  const navlinks = webInternalApps.map(app => (
    <NavbarLink
      key={app.name}
      icon={app.icon}
      isSelected={app === currentApp}
      name={app.name}
      themeColor={app.themeColor}
      to={app.routePath}
    />
  ))

  return (
    <Navbar p='xs' width={{base: 250}}>
      {navlinks}
    </Navbar>
  )
}
