/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/naming-convention */
import TextareaFill from '../../web/components/TextareaFill'

import errorSummary from '../../core/errorSummary'
import nullthrows from 'nullthrows'
import ohm from 'ohm-js'
import useWebInternalParserRenderer from '../hooks/useWebInternalParserRenderer'

import {Alert, SimpleGrid} from '@mantine/core'
import type {ChangeEvent, ReactElement} from 'react'
import React, {useCallback} from 'react'

const grammar = ohm.grammar(String.raw`
  Calculator {
    Statements
      = Statement (#"\n" Statement)*

    Statement
      = Conversion
      | ConversionQuestion
      | Seed

    Conversion
      = num unit "/" unit     -- simple
      | num unit "/" num unit -- complex

    ConversionQuestion
      = unit "/" unit

    Seed
      = num unit

    num
      = digit* "." digit+   -- fract
      | digit+ "."?         -- whole

    unit
      = "$"
      | letter+
  }
`)

export default function WebInternalCalculator(): ReactElement {
  const {input, onInputChange, state} = useWebInternalParserRenderer({
    defaultInput: ['34 miles/gallon', '6 $/gallon', '1000 miles'].join('\n'),
    parse: parseResolve,
  })
  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onInputChange(event.currentTarget.value)
    },
    [onInputChange],
  )

  return (
    <SimpleGrid cols={2} sx={{height: '100%'}}>
      <TextareaFill onChange={onChange} value={input}></TextareaFill>
      {state instanceof Error ? (
        <Alert color='red' title='Error' variant='outline'>
          {errorSummary(state)}
        </Alert>
      ) : (
        <TextareaFill readOnly value={state}></TextareaFill>
      )}
    </SimpleGrid>
  )
}

interface ParseResult {
  conversionQuestions: ConversionQuestionNode[]
  conversions: ConversionNode[]
  seed: SeedNode
}

interface ConversionNode {
  type: 'conversion'
  unit1: string
  unit1to2: number
  unit2: string
  unit2to1: number
}

interface ConversionQuestionNode {
  type: 'conversionQuestion'
  unit1: string
  unit2: string
}

interface SeedNode {
  type: 'seed'
  unit: string
  value: number
}

type StatementNode = ConversionNode | ConversionQuestionNode | SeedNode

interface StatementsNode {
  statements: StatementNode[]
  type: 'statements'
}

type Node =
  | ConversionNode
  | ConversionQuestionNode
  | SeedNode
  | StatementsNode
  | {
      type: 'num'
      value: number
    }
  | {
      type: 'unit'
      unit: string
    }

function parse(input: string): ParseResult {
  const semantics = grammar.createSemantics()
  semantics.addOperation<Node>('eval', {
    ConversionQuestion(unit1, _, unit2) {
      return {
        type: 'conversionQuestion',
        unit1: unit1.eval().unit,
        unit2: unit2.eval().unit,
      }
    },
    Conversion_complex(num1, unit1, _, num2, unit2) {
      return {
        type: 'conversion',
        unit1: unit1.eval().unit,
        unit1to2: num2.eval().value / num1.eval().value,
        unit2: unit2.eval().unit,
        unit2to1: num1.eval().value / num2.eval().value,
      }
    },
    Conversion_simple(num, unit1, _, unit2) {
      return {
        type: 'conversion',
        unit1: unit1.eval().unit,
        unit1to2: 1.0 / num.eval().value,
        unit2: unit2.eval().unit,
        unit2to1: num.eval().value,
      }
    },
    Seed(num, unit) {
      return {
        type: 'seed',
        unit: unit.eval().unit,
        value: num.eval().value,
      }
    },
    Statement(node) {
      return node.eval()
    },
    Statements(first, _, remainder) {
      return {
        statements: [first.eval()].concat(
          remainder.children.map(node => node.eval()),
        ),
        type: 'statements',
      }
    },
    num(_) {
      return {
        type: 'num',
        value: parseFloat(this.sourceString),
      }
    },
    unit(_) {
      return {
        type: 'unit',
        unit: this.sourceString,
      }
    },
  })

  const match = grammar.match(input, 'Statements')
  const adapter = semantics(match)
  const statements: StatementsNode = adapter.eval()

  const seeds = statements.statements
    .filter(statement => statement.type === 'seed')
    .map(statement => statement as SeedNode)
  switch (seeds.length) {
    case 0:
      throw new Error('No seed found, expected 1 seed')
    case 1:
      break
    default:
      throw new Error('Multiple seeds found, expected 1 seed')
  }

  return {
    conversionQuestions: statements.statements
      .filter(statement => statement.type === 'conversionQuestion')
      .map(statement => statement as ConversionQuestionNode),
    conversions: statements.statements
      .filter(statement => statement.type === 'conversion')
      .map(statement => statement as ConversionNode),
    seed: seeds[0],
  }
}

function resolve({
  conversionQuestions,
  conversions,
  seed,
}: ParseResult): string {
  const units = new Set<string>()
  for (const conversion of conversions) {
    units.add(conversion.unit1)
    units.add(conversion.unit2)
  }

  if (!units.has(seed.unit)) {
    throw new Error('No conversions from ' + seed.unit)
  }

  const values = new Map<string, number>()
  values.set(seed.unit, seed.value)
  let lastValuesSize = values.size

  while (true) {
    for (const unit of units) {
      if (values.has(unit)) {
        // Unit already resolved.
        continue
      }

      // Try to resolve this unit using the conversions.
      for (const conversion of conversions) {
        if (conversion.unit1 === unit && values.has(conversion.unit2)) {
          const value2 = nullthrows(
            values.get(conversion.unit2),
            `Unit doesn't exist: ${conversion.unit2}`,
          )
          values.set(unit, value2 * conversion.unit2to1)
        } else if (conversion.unit2 === unit && values.has(conversion.unit1)) {
          const value1 = nullthrows(
            values.get(conversion.unit1),
            `Unit doesn't exist: ${conversion.unit1}`,
          )
          values.set(unit, value1 * conversion.unit1to2)
        }
      }
    }

    if (values.size === lastValuesSize) {
      // Resolution has stabilized.
      break
    }
    lastValuesSize = values.size
  }

  let result = ''
  for (const [unit, value] of values.entries()) {
    result += `${value} ${unit}\n`
  }

  for (const {unit1, unit2} of conversionQuestions) {
    const value1 = nullthrows(values.get(unit1), `Unit doesn't exist: ${unit1}`)
    const value2 = nullthrows(values.get(unit2), `Unit doesn't exist: ${unit2}`)
    result += `${value1 / value2} ${unit1} / ${unit2}\n`
  }

  return result
}

function parseResolve(input: string): string {
  const result = parse(input)
  return resolve(result)
}
