/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/naming-convention */
import * as BinPacking from 'binpackingjs'
import TextareaFill from '../../web/components/TextareaFill'

import errorSummary from '../../core/errorSummary'
import ohm from 'ohm-js'
import useWebInternalParserRenderer from '../hooks/useWebInternalParserRenderer'

import {Alert, SimpleGrid} from '@mantine/core'
import type {ChangeEvent, ReactElement} from 'react'
import React, {useCallback} from 'react'

interface DefinitionNode {
  nodes: Node[]
  type: 'definition'
}

interface BinNode {
  dim0: number
  dim1: number
  dim2: number
  label: string
  type: 'bin'
}

interface ItemNode {
  dim0: number
  dim1: number
  dim2: number
  label: string
  type: 'item'
}

type Node =
  | DefinitionNode
  | BinNode
  | ItemNode
  | {
      dim0: number
      dim1: number
      dim2: number
      type: 'dimensions'
    }
  | {
      type: 'float'
      value: number
    }
  | {
      label: string
      type: 'label'
    }

const grammar = ohm.grammar(String.raw`
  BinPacking {
    Definition = ((Bin | Item))+
    Bin = "#" label ":" dimensions
    Item = label ":" dimensions

    label = (alnum | "~" | "!" | "@" | "$" | "%" | "^" | "&" | "*" | "(" | ")" | "-" | "_" | "+" | "=" | "[" | "]" | "{" | "}" | "|" | "\\" | ";" | "\'" | "\"" | "," | "." | "<" | ">" | "/" | "?" | " " | "\t")+
    dimensions = float " " float " " float
    float = digit+ "."? digit*
  }
`)

const constants = {
  maxBins: 20,
}

export default function WebInternalBinPacking(): ReactElement {
  const {input, onInputChange, state} = useWebInternalParserRenderer({
    defaultInput: () =>
      [
        '#Small: 2 2 5',
        '#Medium: 3 3 5',
        'item1: 1 1 2',
        'item2: 1 1 2',
        'item3: 1 1 2',
        'item4: 3 3 5',
      ].join('\n'),
    parse,
  })

  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onInputChange(event.currentTarget.value)
    },
    [onInputChange],
  )

  return (
    <>
      <SimpleGrid cols={2} sx={{height: '100%'}}>
        <TextareaFill onChange={onChange} value={input}></TextareaFill>
        {state instanceof Error ? (
          <Alert color='red' title='Error' variant='outline'>
            {errorSummary(state)}
          </Alert>
        ) : (
          <TextareaFill readOnly value={state} />
        )}
      </SimpleGrid>
    </>
  )
}

function parse(input: string): string {
  const semantics = grammar.createSemantics()
  semantics.addOperation<Node>('eval', {
    Bin(_1, label, _2, dimensions) {
      return {
        ...dimensions.eval(),
        label: label.eval().label,
        type: 'bin',
      }
    },
    Definition(nodes) {
      return {
        nodes: nodes.children.map(node => node.eval()),
        type: 'definition',
      }
    },
    Item(label, _, dimensions) {
      const dimensionsNode = dimensions.eval()
      return {
        ...dimensionsNode,
        label: label.eval().label,
        type: 'item',
      }
    },
    dimensions(dim0, _1, dim1, _2, dim2) {
      return {
        dim0: dim0.eval().value,
        dim1: dim1.eval().value,
        dim2: dim2.eval().value,
        type: 'dimensions',
      }
    },
    float(_1, _2, _3) {
      return {
        type: 'float',
        value: parseFloat(this.sourceString),
      }
    },
    label(_) {
      return {
        label: this.sourceString,
        type: 'label',
      }
    },
  })

  const match = grammar.match(input, 'Definition')
  const adapter = semantics(match)
  const definition: DefinitionNode = adapter.eval()

  const bins = definition.nodes
    .filter(node => node.type === 'bin')
    .map(bin => bin as BinNode)
  const items = definition.nodes
    .filter(node => node.type === 'item')
    .map(bin => bin as ItemNode)
  const totalItemVolume = items.reduce(
    (value, item) => value + item.dim0 * item.dim1 * item.dim2,
    0,
  )
  let totalCombinations = 0
  let skippedCombinations = 0

  const binCounts = []
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const _bin of bins) {
    binCounts.push(0)
  }
  let bestPacker: BinPacking.BP3D.Packer | null = null
  let bestEfficiencyPct = 0
  while (true) {
    let reachedEnd = false
    for (let binIndex = 0; binIndex < binCounts.length; binIndex++) {
      binCounts[binIndex]++
      if (binCounts[binIndex] < constants.maxBins) {
        break
      }
      binCounts[binIndex] = 0
      if (binIndex === binCounts.length - 1) {
        reachedEnd = true
      }
    }

    if (reachedEnd) {
      break
    }

    totalCombinations++

    // Compute the total volume so we can skip this iteration if it is insufficient.
    let totalBinVolume = 0
    for (let binIndex = 0; binIndex < binCounts.length; binIndex++) {
      const bin = bins[binIndex]
      totalBinVolume += binCounts[binIndex] * bin.dim0 * bin.dim1 * bin.dim2
    }
    if (totalItemVolume > totalBinVolume) {
      // Complete packing is impossible.
      skippedCombinations++
      continue
    }

    const packer = new BinPacking.BP3D.Packer()
    for (let binIndex = 0; binIndex < binCounts.length; binIndex++) {
      for (
        let binInstanceIndex = 0;
        binInstanceIndex < binCounts[binIndex];
        binInstanceIndex++
      ) {
        const bin = bins[binIndex]
        packer.addBin(
          new BinPacking.BP3D.Bin(
            bin.label + binInstanceIndex.toString(),
            bin.dim0,
            bin.dim1,
            bin.dim2,
            100,
          ),
        )
      }
    }

    for (const item of items) {
      packer.addItem(
        new BinPacking.BP3D.Item(
          item.label,
          item.dim0,
          item.dim1,
          item.dim2,
          0,
        ),
      )
    }

    packer.pack()
    if (packer.unfitItems.length === 0) {
      const efficiencyPct = Math.round(
        (totalItemVolume * 100.0) / totalBinVolume,
      )
      if (efficiencyPct > bestEfficiencyPct) {
        bestEfficiencyPct = efficiencyPct
        bestPacker = packer
      }
    }
  }

  const results = []
  if (bestPacker == null) {
    results.push('No packing found')
  } else {
    results.push(`# Overall efficiency: ${bestEfficiencyPct}%`)
    results.push(
      `# ${bestPacker.bins.length} bins, skipped ${skippedCombinations} combinations, ${totalCombinations} total combinations`,
    )

    for (const bin of bestPacker.bins) {
      const totalPackerBinItemVolume = bin.items.reduce(
        (value, item) => value + item.getVolume(),
        0,
      )
      const pct = Math.round(
        (totalPackerBinItemVolume * 100.0) / bin.getVolume(),
      )
      results.push(`# ${bin.name} (${pct}%)`)
      for (const item of bin.items) {
        results.push(`- ${item.name}`)
      }
    }
  }

  return results.join('\n')
}
