import type {ReactElement} from 'react'
import WebInternalAppDebugModal from './WebInternalAppDebugModal'

import {useAuth0} from '@auth0/auth0-react'
import {useWebInternalApp} from './webInternalApps'

import {Avatar, Divider, Group, Header, Menu, ThemeIcon} from '@mantine/core'
import React, {useState} from 'react'

export default function WebInternalAppHeader(): ReactElement {
  const app = useWebInternalApp()
  const {logout, user} = useAuth0()
  const [debugVisible, setDebugVisible] = useState(false)

  return (
    <Header height={60} p='xs'>
      <Group position='apart' px={10} sx={{height: '100%'}}>
        <Group>
          <ThemeIcon color={app.themeColor} size='xl' variant='filled'>
            <app.icon size={32} />
          </ThemeIcon>
          <strong>{app.name}</strong>
        </Group>
        <Group position='right'>
          <Menu>
            <Menu.Target>
              <Avatar key={user?.picture} radius='xl' src={user?.picture} />
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>
                {user?.name} ({user?.email})
              </Menu.Label>
              <Divider />
              <Menu.Item color='red' onClick={() => setDebugVisible(true)}>
                Show debug info
              </Menu.Item>
              <Menu.Item onClick={() => logout()}>Sign out</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
      <WebInternalAppDebugModal
        opened={debugVisible}
        onClose={() => setDebugVisible(false)}
      />
    </Header>
  )
}
