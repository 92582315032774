import React from 'react'
import type {ReactElement} from 'react'
import {Textarea} from '@mantine/core'
import type {TextareaProps} from '@mantine/core'

const styles = {
  input: {height: '100%'},
  wrapper: {height: '100%'},
}

// Textarea component which vertically fills the space.
export default function TextareaFill(props: TextareaProps): ReactElement {
  return <Textarea {...props} styles={styles}></Textarea>
}
