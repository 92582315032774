import FullPage from '../../web/components/FullPage'
import {IconHome} from '@tabler/icons'
import React from 'react'
import type {ReactElement} from 'react'

import {useAuth0} from '@auth0/auth0-react'
import {Button, Stack, Title} from '@mantine/core'

export default function WebInternalAuthLoginPage(): ReactElement {
  const {loginWithRedirect} = useAuth0()
  return (
    <FullPage>
      <Stack align='center'>
        <Title>
          <IconHome color='#888' size={128} />
        </Title>
        <Button onClick={() => void loginWithRedirect()} size='xl'>
          Sign In
        </Button>
      </Stack>
    </FullPage>
  )
}
