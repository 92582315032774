import is from '@sindresorhus/is'
import {useCallback, useState} from 'react'

interface Props<State> {
  // Default input string.
  defaultInput: string | (() => string)

  // Parses the input.
  parse(input: string): State
}

interface Output<State> {
  // Current input string.
  input: string

  // Current parsed state.
  state: State | Error

  // Call when the input string has changed.
  onInputChange(newInput: string): void
}

// Hook to implement UI that parses text input and renders content.
export default function useWebInternalParserRenderer<State>({
  defaultInput,
  parse,
}: Props<State>): Output<State> {
  const [input, setInput] = useState(defaultInput)
  const parseOrError = useCallback(
    (input: string) => {
      try {
        return parse(input)
      } catch (error: unknown) {
        return error as Error
      }
    },
    [parse],
  )
  const [state, setState] = useState(() =>
    parseOrError(is.string(defaultInput) ? defaultInput : defaultInput()),
  )
  const onInputChange = useCallback(
    (newInput: string) => {
      setInput(newInput)
      setState(parseOrError(newInput))
    },
    [parseOrError],
  )
  return {
    input,
    onInputChange,
    state,
  }
}
