import {Link} from 'react-router-dom'
import type {MantineTheme} from '@mantine/core'
import type {ReactElement} from 'react'

import {Group, Text, ThemeIcon, UnstyledButton} from '@mantine/core'
import React, {useCallback} from 'react'

interface TablerIconProps {
  color?: string
  size?: string | number
  stroke?: string | number
}

interface Props {
  icon: React.ElementType<TablerIconProps>
  isSelected: boolean
  name: string
  themeColor: string
  to: string
}

// Displays a link in the navbar.
export default function NavbarLink({
  icon: Icon,
  isSelected,
  name,
  themeColor,
  to,
}: Props): ReactElement {
  const sx = useCallback(
    (theme: MantineTheme) => ({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '&:hover': {
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[5]
            : theme.colors.gray[0],
      },
      backgroundColor: isSelected ? theme.colors.dark[4] : '',
      borderRadius: theme.radius.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
      display: 'block',
      marginBottom: theme.spacing.xs / 2,
      padding: theme.spacing.xs,
      width: '100%',
    }),
    [isSelected],
  )

  return (
    <Link style={{textDecoration: 'none'}} to={to}>
      <UnstyledButton sx={sx}>
        <Group>
          <ThemeIcon color={themeColor} size='lg' variant='light'>
            <Icon />
          </ThemeIcon>
          <Text size='sm'>{name}</Text>
        </Group>
      </UnstyledButton>
    </Link>
  )
}
