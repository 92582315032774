/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/naming-convention */
import ClipboardButton from '../../web/components/ClipboardButton'
import TextareaFill from '../../web/components/TextareaFill'

import errorSummary from '../../core/errorSummary'
import ohm from 'ohm-js'
import {sentenceCase} from 'sentence-case'
import useWebInternalParserRenderer from '../hooks/useWebInternalParserRenderer'

import {Alert, Group, SimpleGrid, Stack, Table} from '@mantine/core'
import type {CSSProperties, ChangeEvent, ReactElement} from 'react'
import React, {useCallback} from 'react'

interface SummaryRowProps {
  isPercent?: boolean
  label: string
  value: number
}

interface Receipt {
  items: ItemNode[]
  subtotal: number
  tax: number
  taxPercent: number
  taxSubtotal: number
  total: number
}

interface ItemNode {
  cost: number
  id: number
  isTaxed: boolean
  label: string
  type: 'item'
}

interface ReceiptNode {
  lines: Node[]
  type: 'receipt'
}

interface SummaryNode {
  type: 'subtotal' | 'tax' | 'total'
  value: number
}

type Node =
  | ItemNode
  | ReceiptNode
  | SummaryNode
  | {
      type: 'member'
    }
  | {
      cost: number
      type: 'cost'
    }
  | {
      id: number
      type: 'itemID'
    }
  | {
      label: string
      type: 'itemLabel'
    }

const grammar = ohm.grammar(String.raw`
  CostcoReceipt {
    Receipt
      = (Member | Item | Summary)+

    Member
      = "Member" digit+

    Item
      = ("E" | "F")? itemID itemLabel cost ("A" | "null")?

    Summary
      = "SUBTOTAL" cost -- subtotal
      | "TAX" cost -- tax
      | "****" "TOTAL" cost -- total

    cost
      = digit+ "." digit+ "-"?

    itemID
      = digit+

    itemLabel
      = (letter | digit | "/" | "-" | "'" | "+" | "%" | "." | " ")+
  }
`)

const styles: Record<string, CSSProperties> = {
  td: {
    userSelect: 'all',
  },
}

export default function WebInternalReceiptCostco(): ReactElement {
  const {input, onInputChange, state} = useWebInternalParserRenderer({
    defaultInput: () =>
      [
        'Member 1100',
        'E',
        '30709',
        'FRESH WILD',
        '39.99',
        'E',
        '18668',
        'CHICKEN TAQ',
        '14.79',
        'SUBTOTAL',
        '897.95',
        'TAX',
        '25.41',
        '****',
        'TOTAL',
        '923.36',
      ].join('\n'),
    parse,
  })

  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onInputChange(event.currentTarget.value)
    },
    [onInputChange],
  )

  return (
    <>
      <SimpleGrid cols={2} sx={{height: '100%'}}>
        <TextareaFill onChange={onChange} value={input}></TextareaFill>
        {state instanceof Error ? (
          <Alert color='red' title='Error' variant='outline'>
            {errorSummary(state)}
          </Alert>
        ) : (
          <ReceiptPanel receipt={state}></ReceiptPanel>
        )}
      </SimpleGrid>
    </>
  )
}

function ReceiptPanel({receipt}: {receipt: Receipt}): ReactElement {
  const ids = receipt.items.map(item => item.id).join('\n')

  const items = receipt.items.map(item => {
    const label = isAnyLower(item.label) ? item.label : sentenceCase(item.label)
    const costWithTax = item.isTaxed
      ? item.cost * (1 + receipt.taxPercent)
      : item.cost

    return (
      <tr key={item.id}>
        <td style={styles.td}>{item.id}</td>
        <td style={styles.td}>
          {item.id} {label}
        </td>
        <td style={styles.td}>{costWithTax.toFixed(2)}</td>
      </tr>
    )
  })

  return (
    <Stack>
      <Table>
        <thead>
          <tr>
            <th>
              <Group spacing='xs'>
                ID
                <ClipboardButton value={ids} />
              </Group>
            </th>
            <th>Item</th>
            <th>Cost with tax</th>
          </tr>
        </thead>
        <tbody>
          {items}
          <SummaryRow label='Item count' value={receipt.items.length} />
          <SummaryRow label='Subtotal' value={receipt.subtotal} />
          <SummaryRow label='Tax subtotal' value={receipt.taxSubtotal} />
          <SummaryRow label='Tax' value={receipt.tax} />
          <SummaryRow
            isPercent
            label='Tax percent'
            value={receipt.taxPercent}
          />
          <SummaryRow label='Total' value={receipt.total} />
        </tbody>
      </Table>
    </Stack>
  )
}

function SummaryRow({isPercent, label, value}: SummaryRowProps): ReactElement {
  const valueText = isPercent
    ? (value * 100).toFixed(2) + '%'
    : value.toFixed(2)
  return (
    <tr>
      <td></td>
      <td style={styles.td}>
        <strong>{label}</strong>
      </td>
      <td style={styles.td}>
        <strong>{valueText}</strong>
      </td>
    </tr>
  )
}

function parse(input: string): Receipt {
  const semantics = grammar.createSemantics()
  semantics.addOperation<Node>('eval', {
    Item(_1, itemID, itemLabel, cost, tag) {
      return {
        cost: cost.eval().cost,
        id: itemID.eval().id,
        isTaxed: tag.sourceString === 'A',
        label: itemLabel.eval().label,
        type: 'item',
      }
    },
    Member(_1, _2) {
      return {
        type: 'member',
      }
    },
    Receipt(lines) {
      return {
        lines: lines.children.map(child => child.eval()),
        type: 'receipt',
      }
    },
    Summary_subtotal(_, cost) {
      return {
        type: 'subtotal',
        value: cost.eval().cost,
      }
    },
    Summary_tax(_, cost) {
      return {
        type: 'tax',
        value: cost.eval().cost,
      }
    },
    Summary_total(_1, _2, cost) {
      return {
        type: 'total',
        value: cost.eval().cost,
      }
    },
    cost(_1, _2, _3, negative) {
      const negativeFactor = negative.sourceString === '-' ? -1 : 1
      return {
        cost:
          parseFloat(_1.sourceString + _2.sourceString + _3.sourceString) *
          negativeFactor,
        type: 'cost',
      }
    },
    itemID(_) {
      return {
        id: parseInt(this.sourceString, 10),
        type: 'itemID',
      }
    },
    itemLabel(_) {
      return {
        label: this.sourceString,
        type: 'itemLabel',
      }
    },
  })

  const match = grammar.match(input, 'Receipt')
  const adapter = semantics(match)
  const receipt: ReceiptNode = adapter.eval()

  const subtotalNode = receipt.lines.filter(
    line => line.type === 'subtotal',
  )[0] as SummaryNode
  const taxNode = receipt.lines.filter(
    line => line.type === 'tax',
  )[0] as SummaryNode
  const tax = taxNode?.value ?? 0
  const taxSubtotal = receipt.lines.reduce(
    (sum, line) =>
      line.type === 'item' && line.isTaxed ? sum + line.cost : sum,
    0,
  )
  const total = receipt.lines.filter(
    line => line.type === 'total',
  )[0] as SummaryNode

  return {
    items: receipt.lines.filter(line => line.type === 'item') as ItemNode[],
    subtotal: subtotalNode?.value ?? 0,
    tax,
    taxPercent: tax / taxSubtotal,
    taxSubtotal,
    total: total?.value ?? 0,
  }
}

function isAnyLower(text: string): boolean {
  for (const c of text) {
    if (c >= 'a' && c <= 'z') {
      return true
    }
  }
  return false
}
